import React, { Fragment } from "react";
import Rating from "react-rating";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
const AvatarSvg = props => (
  <svg width="50px" height="50px" viewBox="0 0 50 50" {...props}>
    <defs>
      <circle id="path-1" cx="25" cy="25" r="25"></circle>
    </defs>
    <g id="**HOMEPAGE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage---Desktop" transform="translate(-703.000000, -1709.000000)">
            <g id="CUSTOMER-REVIEW" transform="translate(42.000000, 1564.000000)">
                <g id="Avatar_1" transform="translate(661.000000, 145.000000)">
                        <mask id="mask-2" fill="white">
                            <use href="#path-1"></use>
                        </mask>
                        <use id="Mask" fill="#FDF2D1" href="#path-1"></use>
                        <path d="M39.7484428,15.9019557 C39.7484428,28.0977968 26.1360727,52 12.4138505,52 C-1.30837174,52 -9.82005001,37.2042813 -15.0993586,19.2042813 C-20.3786671,1.20428127 -1.30837174,-4.79571873 12.4138505,-4.79571873 C26.1360727,-4.79571873 39.7484428,3.70611455 39.7484428,15.9019557 Z" id="Path" fill="#F6BD17" fill-rule="nonzero" mask="url(#mask-2)" ></path>
                        <path d="M-53,-71 C-31.3330772,-68.0387244 -9.59755719,-69.3515566 15.2836355,-29.8678815 C42.4089373,13.1100987 -23.013214,37.3432043 1.33880004,98" id="Path" stroke="#3AC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" mask="url(#mask-2)" ></path>
                    </g>
            </g>
        </g>
    </g>
</svg>
);
class CustomerReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  render() {
    const review = this.props.review;
    //const date = format(parseISO(review.created_at), "MM/dd/yyyy");
    
   
    return (
      <div className="customer-review">
        <header className="customer-review__header">
          <div className="columns is-mobile is-multiline">
            <div className="column is-7-mobile">
              <div className="customer-review__line">
                <div className="columns is-mobile is-multiline">
                  <div className="column is-narrow is-5-mobile">
                    <img src={require("../../images/avatar/Avatar_"+this.props.avatar+".png")} alt=""/>
                  </div>
                  <div className="column is-narrow is-7-mobile">
                    <p className="has-text-weight-bold buyer-name" style={{marginTop:'8px'}}>{review.hasOwnProperty('user') ? review.user.display_name : review.name}</p>
                    <p className="has-text-weight-normal verified-buyer">
                      Verified Buyer
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-narrow is-5-mobile review-rating">
              <Rating
                  className="star-rating readonly"
                  readonly={true}
                  initialRating={review.score}
                  emptySymbol={<label className="star-rating__star">★</label>}
                  fullSymbol={
                    <label className="star-rating__star is-selected">★</label>
                  }
                  style={{marginTop:'12px'}}
                />
            </div>
          </div>
          <div className="columns is-mobile is-multiline review-buyer">
          <div className="column is-8-mobile">
            <p
            className="is-inline has-text-weight-bold"
            dangerouslySetInnerHTML={{ __html: review.title }}
              />
          </div>
          <div className="column is-narrow is-4-mobile">
            {
              review.created_at.indexOf('T') === -1 ?
              <p>{review.created_at}</p>
              :
              false
            }
          </div>
        </div>
        </header>

        <div className="customer-review__inner">
          <div className="customer-review__content">
            <p
              className="customer-review__paragraph"
              dangerouslySetInnerHTML={{
                __html:
                  review.content.split(" ").length > 35
                    ? review.content
                        .split(" ")
                        .slice(0, 34)
                        .join(" ") + " ..."
                    : review.content,
              }}
            />
          </div>
        </div>
        
      </div>
    );
  }
}

export default CustomerReview;
