import React, { Component } from "react";
import { isBrowser } from "react-device-detect";
import "./reasons-to-smile-section.scss";

class ReasonsToSmileSection extends Component {
  render() {
    return (
      <section
        className="section has-white-background is-small is-relative reason-to-smile-section">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6">
              <figure className="image is-1by1">
                <img
                  data-src="https://reasontosmile.wpengine.com/wp-content/uploads/2021/02/shipping-box@2x.png"
                  alt=""
                  className="lazyload"
                />
              </figure>
            </div>
            <div className="column is-5 is-offset-1 has-text-centered-mobile">
              <h1>Reasons to Smile</h1>
              <br />
              <br className="is-hidden-mobile" />
              <div className="columns is-vcentered is-centered">
                <div className="column is-2">
                  <img
                    src={require("../../images/free-shipping.svg")}
                    style={{ marginBottom: "0px" }}
                    alt=""
                  />
                </div>
                <div className="column">
                  <h4 className="has-text-weight-bold">
                    Free Shipping On Orders Over $50
                  </h4>
                  <p>
                    Because we believe loyalty should be rewarded. (Sorry International friends - this perk is only available in the US) 
                  </p>
                </div>
              </div>
              <div className="columns is-vcentered is-centered">
                <div className="column is-2">
                  <img
                    src={require("../../images/cancel-anytime.svg")}
                    style={{ marginBottom: "0px" }}
                    alt=""
                  />
                </div>
                <div className="column">
                  <h4 className="has-text-weight-bold">Cancel Anytime</h4>
                  <p>
                    We’d hate to see you go, but understand that life happens.
                    Cancel your subscription anytime, anywhere.
                  </p>
                </div>
              </div>
              <div className="columns is-vcentered is-centered">
                <div className="column is-2">
                  <img
                    src={require("../../images/get-a-refund.svg")}
                    style={{ marginBottom: "0px" }}
                    alt=""
                  />
                </div>
                <div className="column">
                  <h4 className="has-text-weight-bold">
                    Satisfaction Guaranteed
                  </h4>
                  <p>
                  Your satisfaction means the world to us. If you don’t love Smile CBD within the first three days, return the rest within 30 days for a full refund. Our #1 priority is to keep you smiling.
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    );
  }
}

export default ReasonsToSmileSection;
